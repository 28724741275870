import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  selectSuppliers,
  selectSuppliersIsLoading
} from '../../store/selectors'
import { loadSuppliersAction } from '../../store/actions'
// Styles
import './supplier-list.scss'

export default function SupplierList(props) {
  const isLoading = useSelector(selectSuppliersIsLoading)
  const suppliers = useSelector(selectSuppliers)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadSuppliersAction())
  }, [])
  return (
    <div className="page page-suppliers">
      <div className="header">
        <h2>suppliers-list</h2>
      </div>

      <div className="content">
        {isLoading && <div className="loading">Loading</div>}
        {!isLoading && suppliers && (
          <div className="suppliers-list">
            {suppliers.map(i => {
              return <Link to={`/suppliers/${i.id}`}>
                <div className="supplier-card">
                  <div className="supplier-name">{i.companyName}</div>
                  <div className="capabilities-count">3 Capabilities</div>
                  <div className="score">70%</div>
                </div>
              </Link>
            })}
          </div>
        )}
      </div>
    </div>
  )
}
