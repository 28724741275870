import { alertActionTypes } from '../actions'

export const initialState = {
  alert: null
}

export default (state = initialState, action) => {
  const payload = action.payload
  switch (action.type) {
    case alertActionTypes.SHOW_ALERT:
      return {
        ...state,
        alert: payload
      }

    case alertActionTypes.CLOSE_ALERT:
      return {
        ...state,
        alert: null
      }

    default:
      return state
  }
}
