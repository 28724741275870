import React from 'react'
import './notifications.scss'
import { Link } from 'react-router-dom'

export default function() {
  const notifications = [{
    link: '/capabilities/a',
    text: 'Capability request level 1',
    type: 'request-level',
    date: '13-12-2019 12:00h'
  }, {
    link: '/suppliers/a',
    text: 'Supplier Update',
    type: 'supplier-notification',
    date: '13-12-2019 12:00h'
  }]
  return (<div className="notifications">
      {notifications.map(n => {
        return (
          <Link to={n.link}>
            <div className="notification">
              <div className="notification-title">{n.text}</div>
              <div className="notifiaction-date">{n.date}</div>
            </div>
          </Link>
        )
      })}
    </div>)
}