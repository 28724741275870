import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  selectBuyers,
  selectBuyersIsLoading
} from '../../store/selectors'
import { loadBuyersAction } from '../../store/actions'
// Styles
import './buyer-list.scss'

export default function SupplierList(props) {
  const isLoading = useSelector(selectBuyersIsLoading)
  const buyers = useSelector(selectBuyers)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadBuyersAction())
  }, [])
  return (
    <div className="page page-buyers">
      <div className="header">
        <h2>buyers-list</h2>
      </div>

      <div className="content">
        {isLoading && <div className="loading">Loading</div>}
        {!isLoading && buyers && (
          <div className="buyers-list">
            {buyers.map(i => {
              return <Link to={`/buyers/${i.id}`}>
                <div className="buyer-card">
                  <div className="buyer-name">{i.companyName}</div>
                  <div className="capabilities-count">3 Capabilities</div>
                  <div className="score">70%</div>
                </div>
              </Link>
            })}
          </div>
        )}
      </div>
    </div>
  )
}
