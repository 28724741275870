import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { loadCapabilityAction } from '../../store/actions'
import { selectCapability, selectCapabilitiesIsLoading } from '../../store/selectors'
import { Link } from 'react-router-dom'
// Styles
import './capability-detail.scss'

export default function CapabilityDetail(props) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      loadCapabilityAction(
        props.match.params.capabilityId
      )
    )
  }, [])

  const capability = useSelector(selectCapability)
  const loading = useSelector(selectCapabilitiesIsLoading)

  if (loading) {
    return (<div className="loading">Loading</div>)
  }

  if (!capability) {
    return (<div className="not-found">Not found capability</div>)
  }

  return (
    <div className="page page-capability">
      <div className="header">
        <h2>{capability.title}</h2>
      </div>
      <div className="content">
        <div className="capability-detail">
          <div className="capability-description">
            { capability.description }
          </div>
        </div>
        <div className="capabilities-list">
          {capability.subCapabilities.map(i => {
            return (<Link to={`/capabilities/${capability.id}/${i.id}`}>
              <div className="sub-capability">
                <div className="sub-capability-title">{i.title}</div>
                <div className="sub-capability-supplier">{i.supplier}</div>
              </div>
            </Link>)
          })}
        </div>
      </div>
    </div>
  )
}
