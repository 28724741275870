import Home from './pages/home/home'
import Login from './pages/login/login'
import NotFound from './pages/not-found/not-found'
import BuyerList from './pages/buyers/buyer-list'
import BuyerDetail from './pages/buyers/buyer-detail'
import SupplierDetail from './pages/suppliers/supplier-detail'
import SupplierList from './pages/suppliers/supplier-list'
import CapabilityDetail from './pages/capabilities/capability-detail'
import SubCapabilityDetail from './pages/capabilities/sub-capability-detail'
import CapabilityList from './pages/capabilities/capability-list'

// Basic meta tags
const basicMeta = {
  title: `Airbus DDMS`,
  description: ''
}

const Routes = [
  {
    path: '/',
    exact: true,
    private: false,
    component: Home
  },
  {
    path: '/login',
    exact: true,
    private: false,
    component: Login
  },
  {
    path: '/buyers',
    exact: true,
    private: false,
    component: BuyerList
  },
  {
    path: '/buyers/:buyerId',
    exact: true,
    private: false,
    component: BuyerDetail
  },
  {
    path: '/suppliers',
    exact: true,
    private: false,
    component: SupplierList
  },
  {
    path: '/suppliers/:supplierId',
    exact: true,
    private: false,
    component: SupplierDetail
  },
  {
    path: '/capabilities',
    exact: true,
    private: false,
    component: CapabilityList
  },
  {
    path: '/capabilities/:capabilityId',
    exact: true,
    private: false,
    component: CapabilityDetail
  },
  {
    path: '/capabilities/:capabilityId/:subcapabilityId',
    exact: true,
    private: false,
    component: SubCapabilityDetail
  },
  {
    component: NotFound,
    private: false
  }
].map(r => {
  // Merge metadatas
  return Object.assign(
    {},
    {
      metadata: basicMeta
    },
    r
  )
})

export default Routes
