import React from 'react'
import { useSelector } from 'react-redux'

import { selectUserRole } from '../../store/selectors'
import roles from '../../constants/roles'

// Styles
import './home.scss'

export default function Home(props) {
  const role = useSelector(selectUserRole)

  return (
    <div className="page page-home">
      <div className="header">
        <h1>Airbus DDMS</h1>
        {role === roles.GUEST && <div className="test">GUEST LOGGED</div>}
      </div>
    </div>
  )
}
