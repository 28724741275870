import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import alertState, { initialState as alertInitialState } from './alert.reducer'
import userState, { initialState as userInitialState } from './user.reducer'
import suppliersState, {
  initialState as suppliersInitialState
} from './suppliers.reducer'

import capabilitiesState, {
  initialState as capabilitiesInitialState
} from './capabilities.reducer'

import buyersState, {
  initialState as buyersInitialState
} from './buyers.reducer'

export const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    alertState,
    userState,
    suppliersState,
    capabilitiesState,
    buyersState
  })

export const initialState = {
  alertState: alertInitialState,
  userState: userInitialState,
  suppliersState: suppliersInitialState,
  capabilitiesState: capabilitiesInitialState,
  buyersState: buyersInitialState
}
