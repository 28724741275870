import { buyerActionTypes } from '../actions'

export const initialState = {
  buyers: [],
  buyer: null,
  loading: false,
  error: false
}

export default (state = initialState, action) => {
  const payload = action.payload
  switch (action.type) {
    case buyerActionTypes.LOAD_BUYERS:
      return {
        ...state,
        loading: true,
        error: false
      }

    case buyerActionTypes.LOAD_BUYERS_SUCCESS:
      return {
        ...state,
        buyers: payload,
        loading: false,
        error: false
      }

    case buyerActionTypes.LOAD_BUYERS_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      }

    case buyerActionTypes.LOAD_BUYER:
      return {
        ...state,
        loading: true,
        error: false
      }

    case buyerActionTypes.LOAD_BUYER_SUCCESS:
      return {
        ...state,
        buyer: payload,
        loading: false,
        error: false
      }

    case buyerActionTypes.LOAD_BUYER_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      }

    default:
      return state
  }
}
