import React from 'react'
import { Link } from 'react-router-dom'
import './user-box.scss'
export default (props) => {
  return (
    <div className="user-box" onClick={props.onClick}>
      
      <div className="user-image">
        <img src="/assets/user.png" />
      </div>
      
      <div className="user-notifications">5</div>
    </div>
  )
}