import { put, delay, takeEvery } from 'redux-saga/effects'

import { alertActionTypes, closeAlertAction } from '../actions'

export function* alertOpenSaga({ payload }) {
  yield delay(5000)

  yield put(closeAlertAction())
}

export function* watchAlertOpenSaga() {
  yield takeEvery(alertActionTypes.SHOW_ALERT, alertOpenSaga)
}
