import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { loadCapabilityAction } from '../../store/actions'
import { selectCapability } from '../../store/selectors'
// Styles
import './capability-detail.scss'
import { Crl } from '../../components/crl/crl'

export default function CapabilityCard({ capability }) {
  return (
    <div className="capability-card">
      <div className="capability-title">{capability.title}</div>
      <div className="sub-count">{capability.subCapabilities.length}</div>
      <Crl value={capability.level}></Crl>
    </div>
  )
}
