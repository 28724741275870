import React, { useState } from 'react'

import './activity-stream.scss'
import { CAPABILITY_STATUS } from '../../constants/capabilities'
import { ACTIVITY_STREAM_TYPE } from '../../constants/activity-stream'

const activities = [
  {
    type: ACTIVITY_STREAM_TYPE.TEXT,
    data: {
      initialState: CAPABILITY_STATUS.OPEN
    }
  }
]

function ActivityItem({activity}) {
  if (activity.type === 'comment') {
    return (
      <div className="activity activity-comment">
        <div className="user-image">
          <img src="/assets/user.png" />
        </div>
        <div className="date">{activity.date.toISOString()}</div>
        <div className="text">{activity.text}</div>

      </div>
    )
  }

  if (activity.type === 'file-upload') {
    return (
      <div className="activity activity-file-upload">
        <div className="user-image">
          <img src="/assets/user.png" />
        </div>
        <div className="date">{activity.date.toISOString()}</div>
        <div className="text">{activity.text}</div>

      </div>
    )
  }

  if (activity.type === 'level-up-request') {
    return (
      <div className="activity activity-level-up">
        <div className="user-image">
          <img src="/assets/user.png" />
        </div>
        <div className="date">{activity.date.toISOString()}</div>
        <div className="text">{activity.text}</div>

      </div>
    )
  }
}

export default function ActivityStream(props) {
  const [opened, setOpened] = useState(props.opened || false)
  return (

  <div className="activity-stream">
    <div className="title" onClick={() => setOpened(!opened)}>
      <h3>{props.title}</h3>
    </div>
    <div className={`activities ${opened ? 'open': ''}`}>
      {props.activities.map(activity => {
        return (
          <ActivityItem activity={activity} />
        )
      })}
    </div>
  </div>
  )
}
