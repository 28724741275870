export const capabilitiesActionTypes = {
  LOAD_CAPABILITIES: 'capabilities/LOAD_CAPABILITIES',
  LOAD_CAPABILITIES_SUCCESS: 'capabilities/LOAD_CAPABILITIES_SUCCESS',
  LOAD_CAPABILITIES_ERROR: 'capabilities/LOAD_CAPABILITIES_ERROR',
  LOAD_CAPABILITY: 'capabilities/LOAD_CAPABILITY',
  LOAD_CAPABILITY_SUCCESS: 'capabilities/LOAD_CAPABILITY_SUCCESS',
  LOAD_CAPABILITY_ERROR: 'capabilities/LOAD_CAPABILITY_ERROR'
}

export const loadCapabilitiesAction = payload => {
  return {
    type: capabilitiesActionTypes.LOAD_CAPABILITIES,
    payload
  }
}
export const loadCapabilitiesActionSuccess = payload => {
  return {
    type: capabilitiesActionTypes.LOAD_CAPABILITIES_SUCCESS,
    payload
  }
}

export const loadCapabilitiesActionError = payload => {
  return {
    type: capabilitiesActionTypes.LOAD_CAPABILITIES_ERROR,
    payload
  }
}

export const loadCapabilityAction = payload => {
  return {
    type: capabilitiesActionTypes.LOAD_CAPABILITY,
    payload
  }
}
export const loadCapabilityActionSuccess = payload => {
  return {
    type: capabilitiesActionTypes.LOAD_CAPABILITY_SUCCESS,
    payload
  }
}

export const loadCapabilityActionError = payload => {
  return {
    type: capabilitiesActionTypes.LOAD_CAPABILITY_ERROR,
    payload
  }
}
