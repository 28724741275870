export const alertActionTypes = {
  SHOW_ALERT: 'alerts/SHOW_ALERT',
  CLOSE_ALERT: 'alerts/CLOSE_ALERT'
}

export const showAlertAction = payload => {
  return {
    type: alertActionTypes.SHOW_ALERT,
    payload
  }
}

export const closeAlertAction = payload => {
  return {
    type: alertActionTypes.CLOSE_ALERT,
    payload
  }
}
