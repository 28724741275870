const buyers = [
  {
    id: '276086',
    companyName: '2MATECH',
    cageCode: '#',
    street: '24 AVENUE DES LANDAIS',
    city: 'AUBIERE CEDEX',
    country: 'France',
    productGroup: 'AFM-003-4 TEST LAB'
  },
  {
    id: '305864',
    companyName: '3A COMPOSITES GMBH',
    cageCode: '#',
    street: 'ALUSINGENPLATZ 1',
    city: 'SINGEN',
    country: 'Germany',
    productGroup: 'AFM-002-2 MATERIAL PART MANUFACTURING'
  },
  {
    id: '299679',
    companyName: '3D ICOM GMBH & CO KG',
    cageCode: 'D3402',
    street: 'GEORG-HEYKEN-STR. 6',
    city: 'HAMBURG',
    country: 'Germany',
    productGroup: 'AFM-001-2 AEROSTRUCTURE BUILD TO PRINT'
  },
  {
    id: '309633',
    companyName: '3D ICOM GMBH & CO KG',
    cageCode: '#',
    street: 'ZUM FLIEGERHORST 11',
    city: 'GROSSENHAIN',
    country: 'Germany',
    productGroup: 'AFM-001-2 AEROSTRUCTURE BUILD TO PRINT'
  },
  {
    id: '271379',
    companyName: '3M ASD DIVISON PLANT',
    cageCode: '#',
    street: '801 NO MARQUETTE',
    city: 'PRAIRIE DU CHIEN',
    country: 'USA',
    productGroup: 'AFM-002-2 MATERIAL PART MANUFACTURING'
  },
  {
    id: '285419',
    companyName: '3M CO',
    cageCode: '8M369',
    street: '610 N COUNTY RD 19',
    city: 'ABERDEEN',
    country: 'USA',
    productGroup: 'AFM-002-2 MATERIAL PART MANUFACTURING'
  },
  {
    id: '133367',
    companyName: '3M COMPANY',
    cageCode: '6A670',
    street: '3211 EAST CHESTNUT EXPRESS WAY',
    city: 'SPRINGFIELD',
    country: 'USA',
    productGroup: 'AFM-002-2 MATERIAL PART MANUFACTURING'
  },
  {
    id: '133370',
    companyName: '3M DEUTSCHLAND GMBH',
    cageCode: 'DL851',
    street: 'DUESSELDORFER STR. 121-125',
    city: 'HILDEN',
    country: 'Germany',
    productGroup: 'AFM-002-2 MATERIAL PART MANUFACTURING'
  },
  {
    id: '230295',
    companyName: '3M DEUTSCHLAND GMBH',
    cageCode: 'D2607',
    street: 'CARL SCHURZ STR. 1',
    city: 'NEUSS',
    country: 'Germany',
    productGroup: 'AFM-002-1 MATERIAL DISTRIBUTION'
  },
  {
    id: '133374',
    companyName: '3M ESPANA SL',
    cageCode: '0211B',
    street: 'CL JUAN IGNACIO LUCA DE TENA 19-25',
    city: 'MADRID',
    country: 'Spain',
    productGroup: 'AFM-002-2 MATERIAL PART MANUFACTURING'
  },
  {
    id: '311744',
    companyName: '3M FAIRMONT',
    cageCode: '5K231',
    street: '710 N STATE STREET',
    city: 'FAIRMONT',
    country: 'USA',
    productGroup: 'AFM-002-2 MATERIAL PART MANUFACTURING'
  },
  {
    id: '133379',
    companyName: '3M FRANCE',
    cageCode: 'F0347',
    street: 'ROUTE DE SANCOURT',
    city: 'TILLOY LEZ CAMBRAI',
    country: 'France',
    productGroup: 'AFM-002-2 MATERIAL PART MANUFACTURING'
  },
  {
    id: '308272',
    companyName: '3M FRANCE',
    cageCode: '#',
    street: 'AVENUE BOULE',
    city: 'BEAUCHAMP',
    country: 'France',
    productGroup: 'AFM-003-4 TEST LAB'
  },
  {
    id: '133376',
    companyName: '3M FRANCE SA',
    cageCode: 'F0347',
    street: "BOULEVARD DE L'OISE",
    city: 'CERGY PONTOISE CEDEX',
    country: 'France',
    productGroup: 'AFM-002-2 MATERIAL PART MANUFACTURING'
  },
  {
    id: '133376',
    companyName: '3M FRANCE SA',
    cageCode: 'F0347',
    street: "BOULEVARD DE L'OISE",
    city: 'CERGY PONTOISE CEDEX',
    country: 'France',
    productGroup: 'AFM-002-1 MATERIAL DISTRIBUTION'
  },
  {
    id: '133381',
    companyName: '3M GENERAL OFFICES',
    cageCode: '#',
    street: '3M CENTER BUILDING 224-3W-10',
    city: 'ST PAUL',
    country: 'USA',
    productGroup: 'AFM-002-2 MATERIAL PART MANUFACTURING'
  },
  {
    id: '305699',
    companyName: '3M INDUSTRIAL ADHESIVES AND TAPES',
    cageCode: '0XV20',
    street: '30 COMMERCE ROAD',
    city: 'ROCKLAND',
    country: 'USA',
    productGroup: 'AFM-002-2 MATERIAL PART MANUFACTURING'
  },
  {
    id: '262443',
    companyName: '3M INDUSTRIAL TAPE & SPECIALTIES',
    cageCode: '5H611',
    street: '0304S-075E',
    city: 'HARTFORD CITY',
    country: 'USA',
    productGroup: 'AFM-002-2 MATERIAL PART MANUFACTURING'
  },
  {
    id: '133385',
    companyName: '3M KNOXVILLE',
    cageCode: '#',
    street: '3406 EAST PLEASANT STREET',
    city: 'KNOXVILLE',
    country: 'USA',
    productGroup: 'AFM-002-2 MATERIAL PART MANUFACTURING'
  },
  {
    id: '305704',
    companyName: '3M MENOMONIE PLANT',
    cageCode: '1W355',
    street: '1425 STOKKE PARKWAY',
    city: 'MENOMONIE',
    country: 'USA',
    productGroup: 'AFM-002-2 MATERIAL PART MANUFACTURING'
  },
  {
    id: '268049',
    companyName: '3M MEXICO SA DE CV',
    cageCode: 'SDX46',
    street: 'AV C F E N 520',
    city: 'SAN LUIS POTOSI',
    country: 'Mexico',
    productGroup: 'AFM-002-2 MATERIAL PART MANUFACTURING'
  },
  {
    id: '133390',
    companyName: '3M UNITED KINGDOM PLC',
    cageCode: 'K8767',
    street: 'EXCHANGE QUAY - SALFORD QUAYS',
    city: 'MANCHESTER',
    country: 'Great Britain',
    productGroup: 'AFM-002-1 MATERIAL DISTRIBUTION'
  },
  {
    id: '270502',
    companyName: '3M UNITED KINGDOM PLC',
    cageCode: 'K5515',
    street: 'EUROPEAN DISTRIBUTION CENTRE',
    city: 'BEDFORD',
    country: 'Great Britain',
    productGroup: 'AFM-002-1 MATERIAL DISTRIBUTION'
  },
  {
    id: '289132',
    companyName: '3M WROCLAW SP. Z O.O.',
    cageCode: '1745H',
    street: 'UL KOWALSKA 143',
    city: 'WROCLAW',
    country: 'Poland',
    productGroup: 'AFM-002-2 MATERIAL PART MANUFACTURING'
  },
  {
    id: '286785',
    companyName: '3P PRODUCTOS PLASTICOS PERFORMANTES',
    cageCode: '1327B',
    street: 'CL POUET DE NASIO',
    city: 'RIBARROJA',
    country: 'Spain',
    productGroup: 'AFM-002-2 MATERIAL PART MANUFACTURING'
  }
]

export const getBuyers = () => {
  return Promise.resolve(buyers)
}

export const getBuyer = (buyerId) => {
  return Promise.resolve(buyers.find(buyer => buyer.id === buyerId))
}
