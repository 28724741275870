import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'

import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'

import { createRootReducer, initialState } from './store/reducers'
import sagas from './store/sagas'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { ConnectedRouter } from 'connected-react-router'

const history = createBrowserHistory()

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  createRootReducer(history), // root reducer with router state
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? compose(
        applyMiddleware(sagaMiddleware, routerMiddleware(history)),
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
      )
    : compose(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
)

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)

// then run the sagas
sagaMiddleware.run(sagas)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
