import { put, delay, takeEvery, call } from 'redux-saga/effects'

import {
  capabilitiesActionTypes,
  loadCapabilitiesActionSuccess,
  loadCapabilitiesActionError
} from '../actions'
import { getCapabilities, getCapability } from '../../services'
import { loadCapabilityActionSuccess, loadCapabilityActionError } from '../actions/capabilities.actions'

export function* loadCapabilitiesSaga() {
  yield delay(1000)
  // TODO remove delay  yield put(closeAlertAction())
  try {
    const capabilities = yield call(getCapabilities)
    yield put(loadCapabilitiesActionSuccess(capabilities))
  } catch (e) {
    yield put(loadCapabilitiesActionError(e))
  }
}

export function* watchLoadCapabilitiesSaga() {
  yield takeEvery(capabilitiesActionTypes.LOAD_CAPABILITIES, loadCapabilitiesSaga)
}

export function* loadCapabilitySaga({ payload }) {
  yield delay(1000)
  // TODO remove delay  yield put(closeAlertAction())
  try {
    const capability = yield call(getCapability, payload)
    yield put(loadCapabilityActionSuccess(capability))
  } catch (e) {
    yield put(loadCapabilityActionError(e))
  }
}

export function* watchLoadCapabilitySaga() {
  yield takeEvery(capabilitiesActionTypes.LOAD_CAPABILITY, loadCapabilitySaga)
}
