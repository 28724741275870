import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

// Styles
import './capability-list.scss'
import { loadCapabilitiesAction } from '../../store/actions'
import { selectCapabilities } from '../../store/selectors/capabilities.selector'
import CapabilityCard from './capability-card'

export default function CapabilityList(props) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadCapabilitiesAction())
  }, [])

  const capabilitites = useSelector(selectCapabilities)

  console.log(capabilitites)

  return (
    <div className="page page-capabilities">
      <div className="header">
        <h2>capability-list</h2>
      </div>
      <div className="content">
        <div className="capabilities-list">
          {capabilitites.map(capability => (
            <Link to={`${props.location.pathname}/${capability.id}`}>
              <CapabilityCard capability={capability}></CapabilityCard>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}
