import React from 'react'
import { Link, withRouter, matchPath } from 'react-router-dom'
import './footer.scss'
import { useSelector, useDispatch } from 'react-redux'

function Footer(props) {


  return (
    <footer className="footer">
      
        <div className="anonymous-menu">
          <Link to="/login">Login</Link>
        </div>
    </footer>
  )
}

export default withRouter(Footer)
