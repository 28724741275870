import { userActionTypes } from '../actions'

export const initialState = {
  user: null
}

export default (state = initialState, action) => {
  const payload = action.payload
  switch (action.type) {
    case userActionTypes.LOG_IN:
      return {
        ...state,
        user: payload
      }

    case userActionTypes.LOG_OUT:
      return {
        ...state,
        user: null
      }

    default:
      return state
  }
}
