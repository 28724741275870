export const suppliersActionTypes = {
  LOAD_SUPPLIERS: 'suppliers/LOAD_SUPPLIERS',
  LOAD_SUPPLIERS_SUCCESS: 'suppliers/LOAD_SUPPLIERS_SUCCESS',
  LOAD_SUPPLIERS_ERROR: 'suppliers/LOAD_SUPPLIERS_ERROR',
  LOAD_SUPPLIER: 'suppliers/LOAD_SUPPLIER',
  LOAD_SUPPLIER_SUCCESS: 'suppliers/LOAD_SUPPLIER_SUCCESS',
  LOAD_SUPPLIER_ERROR: 'suppliers/LOAD_SUPPLIER_ERROR'
}

export const loadSuppliersAction = payload => {
  return {
    type: suppliersActionTypes.LOAD_SUPPLIERS,
    payload
  }
}
export const loadSuppliersActionSuccess = payload => {
  return {
    type: suppliersActionTypes.LOAD_SUPPLIERS_SUCCESS,
    payload
  }
}

export const loadSuppliersActionError = payload => {
  return {
    type: suppliersActionTypes.LOAD_SUPPLIERS_ERROR,
    payload
  }
}

export const loadSupplierAction = payload => {
  return {
    type: suppliersActionTypes.LOAD_SUPPLIER,
    payload
  }
}
export const loadSupplierActionSuccess = payload => {
  return {
    type: suppliersActionTypes.LOAD_SUPPLIER_SUCCESS,
    payload
  }
}

export const loadSupplierActionError = payload => {
  return {
    type: suppliersActionTypes.LOAD_SUPPLIER_ERROR,
    payload
  }
}