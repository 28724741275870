import React from 'react'
// Styles
import './crl.scss'

const CRL_LEVELS = 9

// CRL => Capability Readiness Level
export function Crl({ value = 1 }) {
  const levelArr = Array.apply(null, Array(CRL_LEVELS))
  return (
    <p className="crl">
      {levelArr.map((_, i) => {
        return <li className={value == i ? 'active' : ''}>Level {i + 1}</li>
      })}
    </p>
  )
}
