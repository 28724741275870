export const userActionTypes = {
  LOG_IN: 'user/LOG_IN',
  LOG_OUT: 'user/LOG_OUT'
}

export const loginAction = payload => {
  return {
    type: userActionTypes.LOG_IN,
    payload
  }
}

export const logoutAction = payload => {
  return {
    type: userActionTypes.LOG_OUT,
    payload
  }
}
