import { createSelector } from 'reselect'

const getBuyersState = state => state.buyersState || {}

export const selectBuyersIsLoading = createSelector(
  getBuyersState,
  s => s.loading
)

export const selectBuyers = createSelector(
  getBuyersState,
  s => s.buyers
)

export const selectBuyer = createSelector(
  getBuyersState,
  s => s.buyer
)
