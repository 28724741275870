import { suppliersActionTypes } from '../actions'

export const initialState = {
  suppliers: [],
  supplier: null,
  loading: false,
  error: false
}

export default (state = initialState, action) => {
  const payload = action.payload
  switch (action.type) {
    case suppliersActionTypes.LOAD_SUPPLIERS:
      return {
        ...state,
        loading: true,
        error: false
      }

    case suppliersActionTypes.LOAD_SUPPLIERS_SUCCESS:
      return {
        ...state,
        suppliers: payload,
        loading: false,
        error: false
      }

    case suppliersActionTypes.LOAD_SUPPLIERS_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      }

    case suppliersActionTypes.LOAD_SUPPLIER:
      return {
        ...state,
        loading: true,
        error: false
      }

    case suppliersActionTypes.LOAD_SUPPLIER_SUCCESS:
      return {
        ...state,
        supplier: payload,
        loading: false,
        error: false
      }

    case suppliersActionTypes.LOAD_SUPPLIER_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      }

    default:
      return state
  }
}
