import { createSelector } from 'reselect'

const getSuppliersState = state => state.suppliersState || {}

export const selectSuppliersIsLoading = createSelector(
  getSuppliersState,
  s => s.loading
)

export const selectSuppliers = createSelector(
  getSuppliersState,
  s => s.suppliers
)

export const selectSupplier = createSelector(
  getSuppliersState,
  s => s.supplier
)
