import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
// Styles
import './supplier-detail.scss'

import { selectSupplier } from '../../store/selectors'
import { loadSupplierAction } from '../../store/actions'

function SupplierDetail(props) {
  const dispatch = useDispatch()
  const supplier = useSelector(selectSupplier)
  useEffect(() => {
    dispatch(loadSupplierAction(props.match.params.supplierId))
  }, [])
  return (
    <div className="page page-home">
      <div className="header">
        <h2>suppliers-detail</h2>
        {JSON.stringify(supplier)}  
      </div>
    </div>
  )
}

export default withRouter(SupplierDetail)