import React, { useState } from 'react'
import { Link, withRouter, matchPath } from 'react-router-dom'
import './header.scss'
import { useSelector, useDispatch } from 'react-redux'

import UserBox from '../userBox/userBox'
import Notifications from '../notifications/notifications'

import { selectUserIsLoggedIn, selectUserRole } from '../../store/selectors'
import { logoutAction } from '../../store/actions'

function Header(props) {
  const userIsLoggedIn = useSelector(selectUserIsLoggedIn)
  const userRole = useSelector(selectUserRole)
  const dispatch = useDispatch()
  const [notificationsOpen, setNotificationsOpen] = useState(false)

  const logout = () => {
    dispatch(logoutAction())

    props.history.push('/')
  }

  return (
    <div className="app-header">
      <div className="content">
        <ul>
          <li
            className={!!matchPath(props.location.pathname, '/') ? 'active' : ''}
          >
            <Link to={`/`} title="home">
              home
            </Link>
          </li>
          <li
            className={
              !!matchPath(props.location.pathname, '/suppliers')
                ? 'active'
                : ''
            }
          >
            <Link to={`/suppliers`} title="suppliers">
              Suppliers
            </Link>
          </li>
          <li
            className={
              !!matchPath(props.location.pathname, '/buyers')
                ? 'active'
                : ''
            }
          >
            <Link to={`/buyers`} title="buyers">
              Buyers
            </Link>
          </li>
          <li
            className={
              !!matchPath(props.location.pathname, '/capabilities')
                ? 'active'
                : ''
            }
          >
            <Link to={`/capabilities`} title="capabilities">
              Capabilities
            </Link>
          </li>
        </ul>
        {userIsLoggedIn && (
          <div className="user-menu">

            <div className="user">{userRole}</div>
            <div className="logout" onClick={logout}>
              Logout
            </div>
            <UserBox userRole={userRole} onClick={() => setNotificationsOpen(!notificationsOpen)}/>
            {notificationsOpen && <Notifications />}
          </div>
        )}
        {!userIsLoggedIn && (
          <div className="anonymous-menu">
            <Link to="/login">Login</Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default withRouter(Header)
