const capabilities = [
  {
    id: '276086',
    title: 'New Supplier Selection Process - During out of cycle pre-development',
    cluster: 'cluster1',
    group: 'group1',
    startDate: new Date(),
    endDate: new Date(),
    description: 'We are doing a selection process on the cycle pre-development',
    level: 2,
    subCapabilities: [
      {
        id: '123123',
        supplierId: '1',
        title: 'Another capability',
        supplier: 'The supplier',
        description: 'Description for the subcapabilities',
        level: 2,
        evidences: [{
          level: 1,
          type: 'doc',
          filename: 'A doc evidence',
          date: new Date()
        }, {
          level: 1,
          type: 'xlsx',
          title: 'A excel evidence',
          date: new Date()
        }],
        activity: [{
          level: 1,
          id: 'asdasd',
          type: 'comment',
          text: 'This is a comment regarding the activity on level 1',
          date: new Date(),
          userId: '1'
        }, {
          level: 1,
          id: 'asdaasdssd',
          type: 'comment',
          text: 'This is another comment regarding the activity on level 1',
          date: new Date(),
          userId: '1'
        }, {
          level: 1,
          type: 'file-upload',
          id: '123123asd',
          text: 'Uploaded the doc "evidence1.doc"',
          filename: 'evidence1.doc',
          filepath: '/files/evidence1.doc',
          date: new Date(),
          userId: '1'
        }, {
          level: 1,
          type: 'comment',
          text: 'I think the evidences are enough to level up',
          date: new Date(),
          userId: '1'
        }, {
          level: 1,
          type: 'level-up-request',
          text: 'The user requested to level up this capability',
          date: new Date(),
          userId: '1'
        }, {
          level: 1,
          type: 'level-up-request',
          text: 'The capability steering user denied the request',
          date: new Date(),
          userId: '2'
        }, , {
          level: 1,
          type: 'comment',
          text: 'The capability steering user denied the request',
          date: new Date(),
          userId: '2'
        },{
          level: 2,
          type: 'comment',
          text: 'We just arrived to level 2! Congrats',
          date: new Date(),
          userId: '1'
        }]
      },
      // {
      //   id: 'ab',
      //   title: 'A capaability',
      //   supplierId: '2',
      //   supplier: "The other supplier"
      // },
      // {
      //   id: 'abc',
      //   title: 'The third one',
      //   supplierId: '3',
      //   supplier: "zero supplier"
      // },
    ],
  }
]

// TODO: filters
// TODO: service integration
export const getCapabilities = () => {
  return Promise.resolve(capabilities)
}

export const getCapability = (capabilityId) => {
  return Promise.resolve(capabilities.find(capability => capability.id === capabilityId))
}
