import { createSelector } from 'reselect'

const getUserState = state => state.userState || {}

export const selectUserIsLoggedIn = createSelector(
  getUserState,
  us => !!us.user
)

export const selectUserRole = createSelector(getUserState, us =>
  us.user ? us.user.role : null
)
