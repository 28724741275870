import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { loadCapabilityAction } from '../../store/actions'
import { selectCapability, selectCapabilitiesIsLoading } from '../../store/selectors'
import ActivityStream from '../../components/activity-stream/activity-stream'
// Styles
import './sub-capability-detail.scss'

export default function SubCapabilityDetail(props) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      loadCapabilityAction(
        props.match.params.capabilityId
      )
    )
  }, [])

  const capability = useSelector(selectCapability)
  const loading = useSelector(selectCapabilitiesIsLoading)

  if (loading) {
    return (<div className="page page-capability">Loading</div>)
  }

  const subcapabilityId = props.match.params.subcapabilityId

  if (!capability) {
    return (<div className="not-found">Not found subcapability</div>)
  }

  const subcapability = capability.subCapabilities.find(i => i.id === subcapabilityId)

  if (!subcapability) {
    return (<div className="not-found">Not found subcapability</div>)
  }

  return (
    <div className="page page-sub-capability">
      <div className="header">
        <h2>{subcapability.title}</h2>
      </div>
      <div className="content">
        <div className="capability-detail">
          <div className="capability-description">
            { subcapability.description }
          </div>
        </div>
        <div className="evidences">
          <h3>Evidences</h3>
          {subcapability.evidences.map(e => (
            <div className="evidence">
              <div className="level">Level { e.level}</div>
              <div className="filename">{e.filename}</div>
            </div>
          ))}
        </div>
        
        <h3>Activity stream</h3>
        <div className="activity-stream">
          {subcapability.level > 8 && <ActivityStream title="Level 9" opened={subcapability.level === 9} activities={subcapability.activity.filter(i =>i.level === 9)} /> }
          {subcapability.level > 7 && <ActivityStream title="Level 8" opened={subcapability.level === 8} activities={subcapability.activity.filter(i =>i.level === 8)} /> }
          {subcapability.level > 6 && <ActivityStream title="Level 7" opened={subcapability.level === 7} activities={subcapability.activity.filter(i =>i.level === 7)} /> }
          {subcapability.level > 5 && <ActivityStream title="Level 6" opened={subcapability.level === 6} activities={subcapability.activity.filter(i =>i.level === 6)} /> }
          {subcapability.level > 4 && <ActivityStream title="Level 5" opened={subcapability.level === 5} activities={subcapability.activity.filter(i =>i.level === 5)} /> }
          {subcapability.level > 3 && <ActivityStream title="Level 4" opened={subcapability.level === 4} activities={subcapability.activity.filter(i =>i.level === 4)} /> }
          {subcapability.level > 2 && <ActivityStream title="Level 3" opened={subcapability.level === 3} activities={subcapability.activity.filter(i =>i.level === 3)} /> }
          {subcapability.level > 1 && <ActivityStream title="Level 2" opened={subcapability.level === 2} activities={subcapability.activity.filter(i =>i.level === 2)} /> }
          <ActivityStream title="Level 1" opened={subcapability.level === 1} activities={subcapability.activity.filter(i =>i.level === 1)} />
        </div>
      </div>
    </div>
  )
}
