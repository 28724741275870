import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
// Styles
import './buyer-detail.scss'

import { selectBuyer } from '../../store/selectors'
import { loadBuyerAction } from '../../store/actions'

function BuyerDetail(props) {
  const dispatch = useDispatch()
  const buyer = useSelector(selectBuyer)
  useEffect(() => {
    dispatch(loadBuyerAction(props.match.params.buyerId))
  }, [])
  return (
    <div className="page page-home">
      <div className="header">
        <h2>buyer-detail</h2>
        {JSON.stringify(buyer)}  
      </div>
    </div>
  )
}

export default withRouter(BuyerDetail)