import { capabilitiesActionTypes } from '../actions'

export const initialState = {
  capabilities: [],
  capability: null,
  loading: false,
  error: false
}

export default (state = initialState, action) => {
  const payload = action.payload
  switch (action.type) {
    case capabilitiesActionTypes.LOAD_CAPABILITIES:
      return {
        ...state,
        loading: true,
        error: false
      }

    case capabilitiesActionTypes.LOAD_CAPABILITIES_SUCCESS:
      return {
        ...state,
        capabilities: payload,
        loading: false,
        error: false
      }

    case capabilitiesActionTypes.LOAD_CAPABILITIES_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      }

    case capabilitiesActionTypes.LOAD_CAPABILITY:
      return {
        ...state,
        loading: true,
        error: false
      }

    case capabilitiesActionTypes.LOAD_CAPABILITY_SUCCESS:
      return {
        ...state,
        capability: payload,
        loading: false,
        error: false
      }

    case capabilitiesActionTypes.LOAD_CAPABILITY_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      }

    default:
      return state
  }
}
