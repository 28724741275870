export const buyerActionTypes = {
  LOAD_BUYERS: 'capabilities/LOAD_BUYERS',
  LOAD_BUYERS_SUCCESS: 'capabilities/LOAD_BUYERS_SUCCESS',
  LOAD_BUYERS_ERROR: 'capabilities/LOAD_BUYERS_ERROR',
  LOAD_BUYER: 'capabilities/LOAD_BUYER',
  LOAD_BUYER_SUCCESS: 'capabilities/LOAD_BUYER_SUCCESS',
  LOAD_BUYER_ERROR: 'capabilities/LOAD_BUYER_ERROR'
}

export const loadBuyersAction = payload => {
  return {
    type: buyerActionTypes.LOAD_BUYERS,
    payload
  }
}
export const loadBuyersActionSuccess = payload => {
  return {
    type: buyerActionTypes.LOAD_BUYERS_SUCCESS,
    payload
  }
}

export const loadBuyersActionError = payload => {
  return {
    type: buyerActionTypes.LOAD_BUYERS_ERROR,
    payload
  }
}

export const loadBuyerAction = payload => {
  return {
    type: buyerActionTypes.LOAD_BUYER,
    payload
  }
}
export const loadBuyerActionSuccess = payload => {
  return {
    type: buyerActionTypes.LOAD_BUYER_SUCCESS,
    payload
  }
}

export const loadBuyerActionError = payload => {
  return {
    type: buyerActionTypes.LOAD_BUYER_ERROR,
    payload
  }
}
