import { all } from 'redux-saga/effects'

import { watchAlertOpenSaga } from './alert.sagas'
import { watchLoadSuppliersSaga, watchLoadSupplierSaga } from './suppliers.saga'
import { watchLoadCapabilitiesSaga, watchLoadCapabilitySaga } from './capabilities.saga'
import { watchLoadBuyersSaga, watchLoadBuyerSaga } from './buyers.saga'


export default function* root() {
  yield all([
    // Add here the sagas to be watched
    watchAlertOpenSaga(),
    watchLoadSuppliersSaga(),
    watchLoadCapabilitiesSaga(),
    watchLoadSupplierSaga(),
    watchLoadCapabilitySaga(),
    watchLoadBuyersSaga(),
    watchLoadBuyerSaga()
  ])
}
