import { createSelector } from 'reselect'

const getCapabilitiesState = state => state.capabilitiesState || {}

export const selectCapabilitiesIsLoading = createSelector(
  getCapabilitiesState,
  s => s.loading
)

export const selectCapabilities = createSelector(
  getCapabilitiesState,
  s => s.capabilities
)

export const selectCapability = createSelector(
  getCapabilitiesState,
  s => s.capability
)
